import React, { useState } from "react"
import axios from 'axios'
import { FormattedMessage } from "react-intl"

import { Form, Input, Button, Row, Col, message } from 'antd'

import Seo from '../components/seo'
import Icon from '../components/iconfontCpt'
import Layout from "../components/layout"
import intl from '../intl'

import '../style/contact.scoped.scss'

const phoneRegx = /^1[3-9]\d{9}$/

const Fits = ({ pageContext }) => {
  const pageLang = pageContext.lang

  const apiPrefix = process.env && process.env.NODE_ENV === 'development' ? 'http://jenkins.mogdb.enmotech.com:7004/api' : '/api'

  const requiredError = getFormDesc('formError')
  const emailError = getFormDesc('formEmailError')
  const phoneError = getFormDesc('formPhoneError')

  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm();

  function getFormDesc (type) {
    return intl[pageLang].fits[type]
  }

  const onFinish = async (values) => {
    setLoading(true)
    const fromVal = {
      ...values,
      source: 'MogDB'
    }
    axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    const res = await axios.post(apiPrefix + '/public/mail/fits', fromVal);
    setLoading(false)
    if (res && res.data && res.data.code === 200) {
      message.success("Success");
      form.resetFields();
    } else {
      message.error(res.data && res.data.message || 'error');
    }
  };
  const handleTrim = (e) => {
    e.target.value = e.target.value.replace(/^\s*|\s*$/g, '')
  }
  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
      <Seo title="MogDB: Apply"/>
      <div className={`fit-container ${pageLang}-fit`}>
        <div className="form-box">
          <div className="box-title"><div><FormattedMessage id="fits.pageTitle" /></div></div>
          <Form
            name="basic"
            className="w-contact-form"
            layout="vertical"
            onFinish={onFinish}
            form={form}
            autoComplete="off"
          >
            <Form.Item
              className="mobile-inline-item"
              label={getFormDesc('formlabel1')}
              name="company"
              rules={[{ required: true, message: requiredError }]}
            >
              <Input placeholder={getFormDesc('formPlaceholder1')} onKeyUp={handleTrim}/>
            </Form.Item>
            <Form.Item
              className="mobile-inline-item"
              label={getFormDesc('formlabel2')}
              name="product"
              rules={[{ required: true, message: requiredError }]}
            >
              <Input placeholder={getFormDesc('formPlaceholder2')} onKeyUp={handleTrim}/>
            </Form.Item>
            <Row gutter={20}>
              <Col span={24} md={12}>
                <Form.Item
                  className="mobile-inline-item"
                  label={getFormDesc('formlabel3')}
                  name="version"
                  rules={[{ required: true, message: requiredError }]}
                >
                  <Input placeholder={getFormDesc('formPlaceholder3')}  onKeyUp={handleTrim}/>
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  className="mobile-inline-item"
                  label={getFormDesc('formlabel4')}
                  name="username"
                  rules={[{ required: true, message: requiredError }]}
                >
                  <Input placeholder={getFormDesc('formPlaceholder4')}  onKeyUp={handleTrim}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24} md={12}>
                <Form.Item
                  className="mobile-inline-item"
                  label={getFormDesc('formlabel5')}
                  name="phone"
                  rules={[{ required: true, message: requiredError }, ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || phoneRegx.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(phoneError));
                    },
                  }),]}
                >
                  <Input placeholder={getFormDesc('formPlaceholder5')}/>
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  className="mobile-inline-item"
                  label={getFormDesc('formlabel6')}
                  name="email"
                  rules={[{ required: true, message: requiredError }, { type: 'email', message: emailError }]}
                >
                  <Input placeholder={getFormDesc('formPlaceholder6')}/>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button className="form-btn apply-form-btn" type="primary" htmlType="submit" loading={loading}>
                <Icon type="icon-send" /><span className="btn-text">{getFormDesc('formButton')}</span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Layout>
  )
}
export default Fits